@import "@/styles/base/_colors";
@import "@/styles/mixins/_breakpoint";

.installServiceWrapper {
  :global {
    .install-design-services {
      padding: 73px 0 110px 0;

      @media only screen and (max-width: 1023px) {
        padding: 33px 0 116px 0;
      }

      &__container {
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 2.6%;

        @media only screen and (min-width: 1920px) {
          padding: 0 50px;
        }
      }

      &__title {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1.7px;
        color: $color-slate;
        margin: 0 0 30px 0;
        font-family: "Helvetica Now Text W05 Light";
      }

      &__zip-code-message {
        font-family: "Helvetica Now Text W05 Medium";
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.4px;
        color: $color-slate;
      }

      &__success-error-message {
        margin: 10px 0 0 0;
        font-size: 1rem;
        line-height: 1.375rem;
        letter-spacing: -0.0344rem;
        color: $color-dark-gray;
        font-family: "Helvetica Now Text W05 Regular";
      }

      .product-card-service__zipcode-availability {
        padding-top: 20px;
        display: flex;
        align-items: center;
        .input-field {
          width: 140px;
          margin-right: 20px;
          padding: 0;
          margin-bottom: 0px;
        }

        .intall-service-search {
          width: 178px;

          &.black {
            color: $color-white;
          }
        }
      }

      .install__zipcode {
        font-size: 45px;
        line-height: 56px;
        letter-spacing: -3.19px;
        color: $color-slate;
        font-family: "Helvetica Now Text W05 Light";
        margin: 0 15px 0 0;
      }

      .install-service__eligible-service-edit {
        font-size: 20px;
        font-family: "Helvetica Now Text W05 Medium";
        line-height: 20px;
        letter-spacing: -0.4px;
        color: $color-slate;
        padding: 7px 0 5px 0px;
        border-bottom: 1px solid;
        cursor: pointer;
      }
    }

    .install-services-lists {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      display: grid;

      @media only screen and (min-width: 1600px) {
        grid-gap: 79.99px;
      }

      grid-gap: 40px;
      margin: 50px 0 0 0;

      @include breakpoint(desktop-mid) {
        grid-gap: 38px;
      }

      @media only screen and (max-width: 1023px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: unset;
        grid-column-gap: 69px;
        grid-row-gap: 60px;
      }

      @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 64px;
      }

      &__card {
        @media only screen and (min-width: 767px) {
          &.show-add-cart-button {
            position: relative;
            padding: 0 0 65px 0;
          }
        }
      }

      &__image {
        img {
          width: 100%;
        }
      }

      &__information {
        margin: 10px 0 0 0;
      }

      &__detail-section {
        width: 89%;
        float: left;

        @media only screen and (max-width: 1285px) {
          width: 86%;
        }

        &--name {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.4px;
          color: $color-slate;
          $font-family: "Helvetica Now Text W05 Medium";
          margin: 0 0 10px 0;

          @media only screen and (max-width: 1023px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        &--description {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          font-family: "Helvetica Now Text W05 Regular";

          @media only screen and (max-width: 1023px) {
            line-height: 24px;
            letter-spacing: -0.55px;
          }
        }
      }

      &__price-section {
        float: right;

        p {
          font-size: 16px;
          font-family: "Helvetica Now Text W05 Medium";
          line-height: 22px;
          letter-spacing: -0.8px;
          color: $color-slate;
        }
      }

      &__price-and-cart {
        @media only screen and (min-width: 767px) {
          position: absolute;
          bottom: 0;
          z-index: 1;
          width: 100%;
        }

        @media only screen and (max-width: 767px) {
          margin: 30px 0 0 0;
        }
      }

      .cartAction {
        display: flex;
      }

      .quantity-selector-container {
        margin-right: 20px;

        &.quantity-selector {
          border: 1px solid $color-dark-gray;
        }

        .quantity-selector {
          height: 40px;
          width: 90px;

          .plus {
            width: 10px;
            height: 10px;

            .line {
              &.line-2 {
                -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
              }
            }
          }

          &__count {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.7px;
            font-family: "Helvetica Now Text W05 Regular";
            color: $color-slate;
          }
        }
      }

      .add-to-cart-container {
        width: 100%;

        .button {
          height: 40px;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.25px;
        }
      }

      .individual-service-not-available {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        font-family: "Helvetica Now Text W05 Medium";
        color: $color-slate;
        display: flex;
        align-items: center;
        min-height: 40px;

        @media only screen and (max-width: 1023px) {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.3px;
        }

        @media only screen and (max-width: 767px) {
          min-height: unset;
        }
      }
    }
  }
}
