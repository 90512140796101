@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.literatureWrapper {
  :global {
    .literature-tile {
      position: relative;
      max-width: 457px;

      &__widgets {
        display: flex;
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        gap: 5px;
      }

      &__share-icon {
        width: 36px;
        height: 36px;
        background: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      &:hover {
        .literature-tile__widgets {
          opacity: 1;
        }
      }
      &__title {
        margin-top: 20px;
        margin-bottom: 10px;
        color: $color-slate;
        font-family: "Helvetica Now Text W05 Light";
        font-size: 26px;
        font-weight: 300;
        letter-spacing: -1.5px;
        line-height: 34px;
      }

      &__image {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        @media print {
          page-break-inside: avoid;
        }
      }
      &__download-cta {
        display: flex;
        gap: 10px;
        align-items: center;
        color: $color-dark-gray;
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;

        img {
          width: 18px;
          height: 20px;
        }
        &:hover {
          text-decoration: none;
          color: $color-black;
        }
      }
    }
    .sort {
      &__toggle {
        line-height: 30px;
      }
    }
    .literature-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 70px 5px;
      margin-bottom: 70px;

      &__one-col {
        grid-template-columns: 1fr;
        gap: 40px 0px;

        .literature-tile__title {
          margin-bottom: 15px;
        }
      }

      &__two-col {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 5px;

        .literature-tile__title {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      &__grid-control {
        justify-content: flex-end !important;
        margin-top: -45px;
      }
    }

    .product-list__tiles-literature {
      .filter__print-and-share {
        margin-top: 15px;
      }
    }

    .literature {
      .product-list__filter {
        z-index: 1;
      }
      .product-list__tile-col.product-list__tile-col--full-width.literature-list__tile-col {
        margin: 0;
        position: relative;
        z-index: 3;
        background: $color-white;
      }
      .product-list__sticky-nav-wrap {
        z-index: 4;
        padding-bottom: 40px;
      }
      .product-list__controls {
        height: auto;
      }
      .product-list__controls section {
        min-height: auto;
      }
      .product-list__sticky-nav-container {
        .product-list__controls {
          height: 65px;
        }
        .product-list__controls section {
          min-height: 65px;
        }
      }
    }

    main.literature {
      padding-bottom: 120px;
      .kf-page-title__total {
        margin-top: 60px;
      }
      @media only screen and (max-width: 860px) {
        padding-bottom: 0px;
        .kf-page-title__total {
          margin-top: 40px;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      .literature {
        .product-list__controls {
          margin-top: 15px;
        }
        .product-list__sticky-nav-container {
          .product-list__controls {
            margin-top: 0;
          }
        }
      }
    }
    @media only screen and (max-width: 860px) and (orientation: landscape),
      only screen and (min-width: 768px) and (max-width: 991px) {
      .literature-tile {
        &__title {
          color: $color-slate;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 16px;
          letter-spacing: -0.55px;
          line-height: 24px;
        }
      }

      .product-list__tile-col.literature-list__tile-col {
        margin: 0;
      }

      .literature {
        .product-list__filter {
          z-index: 25;

          .filter__inner-container {
            .filter__mobile-header {
              display: flex;
              height: 30px;
              margin: 9px 0 40px;
              justify-content: space-between;
            }
          }
        }
        .kf-page-title__total {
          margin-bottom: 21px;
        }
        .product-list__grid-control {
          padding-bottom: 0;
        }
        .literature-list__grid-control {
          margin-top: 0;
        }
      }
    }
    @media screen and (min-width: 767px) {
      .product-list__tile-col.product-list__tile-col--full-width.literature-list__tile-col {
        margin: 0;
      }
    }
    @media screen and (max-width: 767px) {
      .literature {
        .kf-page-title__total {
          margin-top: 80px;
          height: auto;
          margin-bottom: 18px;
        }
        .product-list__grid-control {
          padding-bottom: 18px;
        }
      }
      .literature-tile {
        &__title {
          font-size: 16px;
          letter-spacing: -0.55px;
          line-height: 24px;
          font-family: "Helvetica Now Text W05 Regular";
        }
      }

      .product-list__tile-col.literature-list__tile-col {
        margin: 0;
      }

      .literature {
        .product-list__filter {
          z-index: 25;
        }
      }
    }
    .kf-react-container {
      max-width: 1444px !important;
    }

    @media print and (min-width: 280px) {
      @page {
        size: A4;
      }
      .product-list__tile-col {
        margin-left: 0px !important;
        transition: none !important;
      }
      .breadcrumb,
      .kf-page-title__total,
      .cmp-teaser__content,
      .cmp-teaser__image {
        display: none;
      }

      .literature-list {
        page-break-inside: avoid;
        break-before: avoid-page;
        break-after: avoid-page;
        break-inside: avoid-page;

        &:first-child {
          margin-top: 50px;
        }
        .literature-list-wrapper {
          break-before: avoid-page;
          break-after: avoid-page;
          break-inside: avoid-page;
          float: left;
        }
      }
      .literature-tile {
        max-width: 100%;
        page-break-inside: avoid;
        break-before: avoid-page;
        break-after: avoid-page;
        break-inside: avoid-page;
        img {
          display: block;
          page-break-inside: avoid;
          break-before: avoid-page;
          break-after: avoid-page;
          break-inside: avoid-page;
        }
      }
    }
  }
}
