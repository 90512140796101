@import "@/styles/base/_colors";

.friendsAndFamilyStyleWrapper {
  :global {
    .ff-membership__terms {
      div:first-child {
        flex: 0;
      }
    }
    .ff-membership {
      max-width: 400px;

      &__header {
        display: flex;
        column-gap: 100px;
      }
      &__block {
        width: 100%;
        margin-bottom: 40px;
        padding: 10px;

        &__close-icon {
          cursor: pointer;
          order: 1;
          float: right;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      &__title {
        order: 0;
        font-family: "Helvetica Now Text W05 Regular", sans-serif;
        font-size: 36px;
        line-height: 1.22;
        letter-spacing: -1.7px;
        color: $color-slate;
      }
      &__description {
        margin: 15px 0px;
        font-family: "Helvetica Now Text W05 Regular", sans-serif;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
      }
      &__description1 {
        margin: 15px 0px;
        font-family: "Helvetica Now Text W05 Regular", sans-serif;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
      }
      &__button {
        margin-top: 0px;
      }
      &__help-contact {
        padding-top: 40px;
        padding-bottom: 40px;
        a,
        a:hover {
          text-decoration: underline;
          color: $color-dark-gray;
        }
        p,
        a {
          font-family: "Helvetica Now Text W05 Regular", sans-serif;
          font-size: 16px;
          letter-spacing: -0.55px;
          line-height: 24px;
          color: $color-dark-gray;
        }
      }
      &__terms {
        padding-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-family: "Helvetica Now Text W05 Regular", sans-serif;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        color: $color-dark-gray;
        a,
        a:hover {
          text-decoration: underline;
          color: $color-dark-gray;
        }
      }
      &__policies {
        display: flex;
        font-family: "Helvetica Now Text W05 Regular", sans-serif;
        justify-content: space-between;
        margin-top: 40px;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        color: $color-dark-gray;
        a {
          margin-right: 20px;
          text-decoration: underline;
          font-family: "Helvetica Now Text W05 Regular", sans-serif;
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: -0.65px;
          color: $color-dark-gray;
          &:hover {
            text-decoration: underline;
            color: $color-black;
          }
        }
      }
    }
    @media only screen and (min-width: 1024px) {
      .success-message-content {
        width: 420px;

        h4 {
          margin-top: 35px;
          margin-bottom: 15px;
        }
        .kf-react-button.large {
          width: 420px;
        }
      }
      .success-message-model {
        margin-bottom: 40px;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
      }
    }
    @media screen and (max-width: 576px) {
      .success-message-content {
        width: 375px;
        h4 {
          margin-top: 0px;
          margin-bottom: 15px;
        }
        .kf-react-button.large {
          width: 339px;
        }
      }
      .success-message-model {
        margin-bottom: 40px;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        width: 295px;
      }
    }

    @media only screen and (min-width: 767px) and (max-device-width: 991px) and (orientation: landscape) {
      .success-message-content {
        width: 812px;
        padding: 15px 128px 232px;

        h4 {
          margin-bottom: 15px;
        }
        .kf-react-button.large {
          width: 476px;
        }
      }
      .success-message-model {
        margin-bottom: 40px;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 24px;
        width: 420px;
      }
    }
  }
}
