@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.myStoreWrapper {
  :global {
    .view-all-stores {
      &__container {
        max-width: 86.25rem;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 60px;
      }

      &__countries-list {
        padding-bottom: 40px;
      }

      &__sticky-nav-wrap {
        position: relative;
        z-index: 1;
      }

      &__sticky-container {
        position: relative;
      }

      &__data-container {
        padding-bottom: 41px;
      }

      &__sticky-nav {
        background: $color-white;
        transition: all 0.3s ease;
        padding-right: 15px;
        padding-left: 15px;

        &--shadow {
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
        }
      }

      &__controls {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 3px 0;

        section {
          display: flex;
        }
        span {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.45px;
          color: $color-charcoal;
        }
      }
      &__filter-toggle-icon {
        width: 21px;
        height: 14px;
        margin-right: 10px;
      }

      &__filter {
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: all 0.5s ease-in-out;
        .filter__chips {
          border-bottom: 1px solid $color-dark-gray;
        }

        .product-list__filter--sticky {
          margin-top: 40px;
        }

        &--hide {
          opacity: 0;
        }

        &--sticky {
          position: sticky;
          top: 80px;
        }
      }

      .filter {
        .Collapsible {
          border-bottom: 1px solid $color-dark-gray;
        }
      }

      &__tile-col {
        transition: all 0.5s ease-in-out;
        margin-left: 60px;

        &--full-width {
          margin-left: 60px;
        }
      }

      &__state-name {
        font-size: 60px;
        font-family: "Helvetica Now Text W05 Light";
        color: $color-slate;
        line-height: 68px;
        letter-spacing: -3.1px;
      }

      &__state-list {
        margin-bottom: 80px;
        .collapsible-accordion .collapsible__trigger--open {
          display: none;
        }
        .collapsible-accordion .Collapsible__contentInner {
          padding-bottom: 0;
        }
        span,
        .view-all-stores__store-description {
          display: block;
          margin-right: 40px;
        }
        .checkbox__border {
          margin-right: 10px;
        }
      }

      &__store-name {
        font-size: 32px;
        font-family: "Helvetica Now Text W05 Light";
        color: $color-slate;
        line-height: 40px;
        letter-spacing: -1.5px;
        margin: 40px 0 20px 0;
      }

      &__store-description {
        font-size: 20px;
        line-height: 28px;
        font-family: "Helvetica Now Text W05 Medium";
        text-decoration: underline;
        color: $color-dark-gray;
        margin-bottom: 15px;
        cursor: pointer;
        &:hover {
          color: $color-dark-gray;
          text-decoration: underline;
        }
      }
      &__store-address-primary,
      &__store-address-secondary,
      &__store-address-zone,
      &__store-mobile,
      &__store-email {
        font-size: 16px;
        line-height: 24px;
        color: $color-dark-gray;
        margin-bottom: 10px;
        font-family: "Helvetica Now Text W05 Regular";
        letter-spacing: -0.55px;
      }
      &__store-address-primary,
      &__store-address-secondary {
        margin-bottom: 0;
      }

      &__store-email {
        margin-bottom: 20px;
      }

      &__set-as-my-store {
        .checkbox {
          margin-bottom: 0;
        }
      }

      .sticky {
        .view-all-stores__countries-list {
          padding-top: 40px;
        }
      }

      #viewAllStore {
        border-bottom: none;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1300px) {
      .view-all-stores {
        &__state-list {
          span,
          .view-all-stores__store-description {
            margin-right: 30px;
            word-break: break-word;
          }
        }
      }
    }

    @media only screen and (max-width: 1024px) {
      .view-all-stores {
        &__data-container {
          padding-bottom: 61px;
        }
        &__state-list {
          margin-bottom: 0px;
        }
        &__filter-toggle {
          display: flex;
          align-items: center;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 1.125rem;
          line-height: 1.33;
          letter-spacing: -0.45px;
          color: $color-charcoal;
          padding: 0;
          margin: 0;
          background: none;
          border: none;
          outline: none;
          &-icon {
            width: 21px;
            height: 30px;
            margin-right: 10px;
            margin-left: 0px;
          }
        }
      }
    }

    @media only screen and (max-width: 860px) and (orientation: landscape),
      only screen and (min-width: 768px) and (max-width: 991px) {
      .view-all-stores {
        margin-top: 85px;
        #viewAllStore {
          border-bottom: 1px solid $color-light-gray;
          &.collapsible--open {
            padding-bottom: 40px;
          }
        }
        &__state-list {
          .collapsible-accordion .collapsible__trigger--open {
            display: block;
          }
          &:first-child {
            .Collapsible {
              border-top: 1px solid $color-light-gray;
            }
          }
        }
        &__countries-list {
          padding-bottom: 0;
        }
        &__data-container {
          padding-bottom: 61px;
        }
        &__controls {
          padding: 33px 0;
          height: auto;
        }
        &__breadcrumb {
          margin-top: 40px;
          margin-bottom: 21px;
        }
        &__container {
          padding: 0px 32px;
        }
        &__filter {
          width: 100%;
          position: fixed;
          z-index: 99;
          top: 0;
          left: 0;
          background: $color-white;
          padding: 0px 32px;
          display: none;
          &--modal {
            display: block;
          }
        }
        .product-list__filter-toggle-icon {
          margin-left: 0px;
          margin-right: 10px;
        }
        &__tile-col--full-width {
          margin-left: 0px;
        }
        &__countries-list {
          .dropdown {
            z-index: 99;
          }
        }
        &__tiles {
          margin-top: 20px;
        }
        &__tile-col {
          margin: auto 0;
        }
        &__tile {
          margin-bottom: 40px;
        }
      }
      .sticky {
        .view-all-stores {
          &__countries-list {
            .dropdown {
              z-index: 99;
            }
          }
          &__controls {
            background-color: $color-white;
            box-shadow: 0px -3px 17px 0px rgba(0, 0, 0, 0.1);
            padding-left: 15px;
            padding-bottom: 15px;
            margin: 0 -15px;
          }
        }
        .view-all-stores__controls {
          background-color: $color-white;
          box-shadow: 0px -3px 17px 0px rgba(0, 0, 0, 0.1);
          padding: 18px 32px;
          border: none;
          margin: 0 -32px;
        }
        .view-all-stores__countries-list {
          display: none;
        }
        .view-all-stores__filter--modal {
          display: block;
          position: relative;
          top: -65px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .view-all-stores {
        .product-list__filter--sticky {
          margin-top: 0px;
        }
        #viewAllStore {
          border-bottom: 1px solid $color-light-gray;
          &.collapsible--open {
            padding-bottom: 40px;
          }
        }
        &__state-list {
          .Collapsible__contentInner {
            &:first-child {
              margin-top: 34px;
            }
          }
          .collapsible-accordion .collapsible__trigger--open {
            display: block;
          }
          &:first-child {
            .Collapsible {
              border-top: 1px solid $color-dark-gray;
            }
          }
        }
        &__breadcrumb {
          margin-top: 20px;
          margin-bottom: 40px;
          padding: 0px 18px;
        }
        &__countries-list {
          padding-bottom: 0px;
          .dropdown {
            z-index: 99;
          }
        }
        &__container {
          padding-left: 18px;
          padding-right: 18px;
        }
        &__controls {
          padding: 33px 0px 43px;
          height: auto;
        }

        &__filter {
          width: 100%;
          position: fixed;
          z-index: 999;
          top: 0;
          left: 0;
          background: $color-white;
          padding: 0px 18px;
          display: none;

          &--modal {
            display: block;
          }
        }

        &__tiles {
          margin-top: 20px;
        }

        &__tile-col {
          margin: auto 0;
        }

        &__tile {
          margin-bottom: 40px;
        }

        &__state-list {
          margin-bottom: 0;
          span,
          .view-all-stores__store-description {
            margin-right: 0;
          }
        }

        &__state-name,
        &__store-name {
          font-size: 26px;
          line-height: 34px;
          letter-spacing: -0.55px;
        }
        &__store-name {
          margin-bottom: 15px;
          margin-top: 0px;
        }

        &__set-as-my-store {
          margin-bottom: 40px;
        }
        .product-list__filter-toggle-icon {
          margin-left: 0px;
          margin-right: 10px;
        }
      }

      .sticky {
        .view-all-stores__controls {
          background-color: $color-white;
          box-shadow: 0px -3px 17px 0px rgba(0, 0, 0, 0.1);
          padding: 18px;
          border: none;
          margin: 0 -15px;
        }

        .view-all-stores__countries-list {
          display: none;
        }
        .view-all-stores__filter--modal {
          display: block;
          position: relative;
          top: -65px;
        }
      }
    }
  }
}
