@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
.findStoreLandingWrapper {
  :global {
    .find-store-landing {
      padding-left: 95px;
      .react-bingmaps {
        z-index: 0;
        height: calc(100% - 110px);
      }

      &__breadcrumb {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: $color-dark-gray;
        margin-top: 30px;
        margin-bottom: 40px;
        display: inline-block;
        .cmp-breadcrumb {
          &__list {
            font-size: 14px;
          }
          &__item {
            padding: 0px;
            &:last-child .cmp-breadcrumb__item-link:after {
              content: "";
            }
          }
          .cmp-breadcrumb {
            &__item-link {
              &:after {
                content: "/";
                margin: 0 2px;
              }
            }
          }
        }
        &-link {
          cursor: pointer;
          font-family: $font-regular;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: $color-dark-gray;
          text-decoration: none;
        }
      }
      &__search {
        position: relative;
      }
      &__search-container {
        .kf-react-button {
          &.ghost {
            &:hover {
              color: $color-slate;
              background-color: transparent;
            }
          }
        }
      }
      &__use-location {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        text-decoration: underline;
        margin-top: 15px;
        margin-bottom: 65px;
        display: inline-block;
        cursor: pointer;
      }

      &__tabs {
        display: flex;
        justify-content: center;
        border-bottom: 0.5px solid $color-dark-gray;
      }

      &__tab {
        font-family: $font-medium;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: -0.4px;
        text-align: center;
        color: $color-dark-gray;
        padding: 0px 30px 15px;
        cursor: pointer;
        border: none;
        background-color: transparent;

        &--active {
          color: $color-charcoal;
          border-bottom: 4px solid $color-charcoal;
          cursor: default;
          margin-bottom: 0px;
        }
      }
      &__list-mb {
        margin-bottom: 110px !important;
      }
      &__list {
        height: 490px;
        overflow-y: auto;
        border-bottom: 1px solid $color-dark-gray;
        margin-bottom: 50px;

        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          background: $color-extra-light-gray;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-dark-gray;
        }
        &::-webkit-scrollbar-button {
          width: 10px;
        }
      }

      &__result-container {
        transition: all 0.5s ease-in-out;
        margin-left: 290px;
        margin-bottom: 95px;
        .error-msg {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.55px;
          text-align: left;
          color: $color-error-red;
          padding-bottom: 25px;
        }
        .find-store-browseall-div {
          display: flex;
          justify-content: space-between;
          .load-more--disabled {
            .kf-react-button.large.ghost {
              background-color: transparent;
              color: $color-medium-gray;
              border: 1px solid $color-medium-gray;
              cursor: not-allowed;
              pointer-events: none;
            }
          }
          .kf-react-button.large {
            min-width: 265px;
          }
        }
      }

      &__result-full {
        margin-left: 0;
      }

      &__message {
        font-family: $font-light;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1.7px;
        color: $color-slate;
        a,
        p {
          font-family: $font-light;
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -1.7px;
          color: $color-slate;
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &__store-message {
        margin-top: 65px;
      }

      .find-store-landing__map {
        position: relative;
        margin-top: -94px;
        padding: 0px 0px 0px 31px;
      }
      &__search-full-container {
        padding-top: 97px;
      }

      &__filter {
        width: 260px;
        height: 100%;
        position: absolute;
        opacity: 1;
        transition: all 0.5s ease-in-out;
        padding-top: 9px;
        .filter__chips {
          border-bottom: 1px solid $color-dark-gray;
        }

        &--hide {
          opacity: 0;
        }
      }

      &__location-note {
        padding-top: 45px;
        display: flex;
        justify-content: center;
        span {
          margin-right: 30px;
          display: inline-block;
          font-family: $font-regular;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 15px;
            height: 21px;
            margin-right: 5px;
          }
        }
      }
      .store-list {
        padding: 35px 20px;
        border-bottom: 1px solid $color-dark-gray;

        &:last-child {
          border-bottom: none;
        }

        &__set-store-time {
          display: none;
          margin-top: 15px;
          text-align: right;
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
        }

        &--active {
          background-color: $color-off-white;
          border-bottom: none;
        }

        &__name {
          font-family: $font-medium;
          font-size: 20px;
          line-height: 1.4;
          letter-spacing: -0.4px;
          color: $color-dark-gray;
          text-decoration: underline;
        }
        &__genuine-dealers {
          text-decoration: none !important;
        }

        &__name-col {
          margin-right: 30px;
        }

        &__address-col {
          margin-right: 20px;
        }

        &__locality,
        &__address {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
        }
        &__address {
          a {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            color: $color-dark-gray;
          }
        }

        &__phone {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          margin-top: 10px;
          color: $color-dark-gray;
          text-decoration: none;
        }

        &__distance-col,
        &__address-col {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
        }

        &__distance-col {
          text-align: left;
        }

        &__address {
          span {
            display: block;
          }
        }

        &__set-store {
          margin-top: 15px;
          display: inline-block;

          .checkbox {
            margin-bottom: 0;
          }
        }
      }

      .find-store-landing__store-card {
        display: block;
        &.--ml {
          display: none;
        }
        @media only screen and (min-width: 540px) and (max-width: 991px) and (orientation: landscape) {
          display: none;
          position: relative;
          &.--ml {
            display: block;
            .store-card {
              position: static;
              margin-top: 0;
              width: 100%;
              .store-card__details {
                padding: 20px 35px 15px;
              }
              .book-appointment {
                display: none;
                &.--mobile {
                  display: block;
                  position: static;
                  .button {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .store-card {
        position: absolute;
        z-index: 98;
        bottom: 110px;
        background: $color-charcoal;
        color: $color-white;
        display: grid;
        grid-template-columns: 2fr 1fr;
        right: 0;
        width: 97%;
        left: 30px;
        padding: 0;

        &.showroom {
          background: $color-dark-gray;
          color: $color-white;

          address > p,
          .store-card-phone > a {
            color: $color-white;
          }

          p,
          .distance {
            color: $color-white;
          }
        }

        &__details {
          display: grid;
          padding: 25px;
          grid-template-columns: 2fr 1fr;
        }

        &__time {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 15px;
        }

        &__name {
          p {
            font-size: 26px;
            line-height: 1.31;
            letter-spacing: -1.55px;
            color: $color-white;
            font-family: $font-light;
            margin-bottom: 0;
          }
          span {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.55px;
            color: $color-white;
          }
        }

        address {
          margin-top: 15px;
        }

        address > p,
        .store-card-phone > a {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-light-gray;
          margin-bottom: 0;
        }

        .book-appointment {
          display: none;
          &.--mobile {
            display: block;
            position: static;
            .button {
              width: 100%;
            }
          }
        }

        &__open {
          p,
          .distance {
            font-family: $font-regular;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: -0.32px;
            color: $color-light-gray;
          }

          .store-card__checkbox {
            .checkbox input ~ span,
            .checkbox input:checked ~ span {
              color: $color-white;
            }
          }

          p {
            margin-bottom: 0;
          }
          .distance {
            margin-bottom: 5px;
          }
          .checkbox {
            margin-top: 10px;
            input ~ span {
              color: $color-light-gray;
              font-family: $font-regular;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: -0.32px;
            }

            input:checked ~ span {
              color: $color-light-gray;
            }

            input ~ .checkbox__border .checkbox__marker {
              .checkbox__marker-icon {
                display: none;
              }
            }
            input:checked ~ .checkbox__border .checkbox__marker {
              background: $color-black;
              border: none;
              .checkbox__marker-icon {
                display: block;
              }
            }
          }
        }

        &__image {
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }

        &__view-details {
          font-family: $font-medium;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: -0.35px;
          color: $color-white;
          text-decoration: underline;
          padding-right: 42px;
        }
      }
    }
    .taxware-down__cta :first-child {
      margin-top: 0;
      &:hover {
        background-color: $primary-blue;
      }
    }
    @media only screen and (min-width: 1920px) {
      .find-store-landing {
        padding-left: 13.2%;
      }
    }
    @media only screen and (min-width: 1581px) and (max-width: 1591px) {
      .find-store-landing {
        padding-left: 89px;
      }
    }
    @media only screen and (min-width: 1569px) and (max-width: 1580px) {
      .find-store-landing {
        padding-left: 82px;
      }
    }
    @media only screen and (min-width: 1560px) and (max-width: 1568px) {
      .find-store-landing {
        padding-left: 75px;
      }
    }
    @media only screen and (min-width: 1520px) and (max-width: 1559px) {
      .find-store-landing {
        padding-left: 70px;
      }
    }
    @media only screen and (min-width: 1451px) and (max-width: 1519px) {
      .find-store-landing {
        padding-left: 46px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1450px) {
      .find-store-landing {
        padding-left: 20px;
        &__filter {
          width: 190px;
        }
        &__result-container {
          margin-left: 220px;
        }
        &__result-full {
          margin-left: 0;
        }
        &__tab,
        &__tab--active {
          padding: 0px 25px 15px;
        }
      }
    }
    @media only screen and (min-width: 1024px) and (max-width: 1366px) {
      .find-store-landing {
        .store-card {
          width: 95.7%;
          &__image {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
        .find-store-landing__result-container {
          .find-store-browseall-div {
            flex-direction: column;
            margin: 0px 18px;
            margin-bottom: 10px;
            width: calc(100% - 36px);
            .load-more {
              margin-bottom: 20px;
            }
            .load-more--disabled {
              margin-bottom: 20px;
            }
            .kf-react-button.large {
              width: 339px;
              justify-content: center;
              &:hover {
                background-color: $color-accent-blue-a;
              }
            }
          }
        }
      }
    }
    @media only screen and (min-width: 991px) and (max-width: 1024px) {
      .find-store-landing {
        padding-left: 20px;
      }
    }
    @media only screen and (max-width: 990px) {
      .find-store-landing {
        padding-left: 0;
        overflow: hidden;
      }
    }
    @media only screen and (min-width: 540px) and (max-width: 991px) and (orientation: landscape) {
      .find-store-landing__map {
        display: none;
        margin-top: -45px !important;
      }
    }
    @media only screen and (max-width: 860px) and (orientation: landscape),
      only screen and (min-width: 768px) and (max-width: 991px) {
      .find-store-landing {
        &__filter {
          width: 100%;
          position: fixed;
          z-index: 999;
          top: 0;
          left: 0;
          background: $color-white;
          padding: 0px 19px;
          display: none;
        }
        &__breadcrumb {
          margin-top: 20px;
          margin-bottom: 20px;
          padding-left: 17px;
        }
        &__filter--modal {
          display: block;
        }
        &__filter-panel {
          position: sticky;
          top: 8px;
        }
        .filter__mobile-header {
          justify-content: space-between;
        }
        .find-store-landing__search-container {
          padding: 0px;
          margin-bottom: 0px;
        }
        &__search {
          margin: 0px 32px;
        }
        &__message {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.65px;
          padding: 0px 32px;
        }
        &__store-message {
          margin-top: 25px;
        }
        &__list {
          border-bottom: none;
          margin-bottom: 40px !important;
        }
        .find-store-landing__map {
          display: none;
          margin-top: -71px;
        }
        .find-store-landing__show-map {
          display: block;
          height: 100vh;
          padding: 0;
          position: unset;
          margin-bottom: 0px;
        }
        &__location-note {
          padding: 13px 32px;
          span {
            margin-right: 15px;
          }
        }
        &__view-list {
          text-decoration: underline;
          font-family: $font-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          margin-bottom: 25px;
          cursor: pointer;
          float: right;
          margin-top: 15px;
          padding-right: 34px;
        }
        .find-store-landing__result-container {
          .error-msg {
            padding: 0px 32px;
            margin-bottom: 25px;
          }
          margin-left: 0px;
          padding: 0px;
          position: relative;
          margin-bottom: 0px;
          .find-store-browseall-div {
            margin-bottom: 50px;
            margin: 20px 32px;
            .kf-react-button.large {
              width: 339px;
              &:hover {
                background-color: $color-accent-blue-a;
              }
            }
          }
        }
        &__show-list {
          padding: 0px 32px;
          height: auto;
          overflow-y: visible;
        }
        &__tabs {
          margin-right: 0;
        }
        &__use-location {
          margin-bottom: 25px;
          text-decoration: underline;
          padding-left: 32px;
        }
        &__tabs {
          overflow-x: auto;
          scroll-behavior: smooth;
          justify-content: center;
          align-items: center;
        }
        &__search-full-container {
          padding-top: 21px;
        }
        &__filter-panel {
          .filter__mobile-header {
            height: auto;
            padding-bottom: 37px;
            padding-top: 22px;
          }
          .filter__chips-tag {
            border: solid 1px $color-medium-gray;
          }
        }
        .store-list {
          &:last-child {
            border-bottom: 1px solid $color-light-gray;
          }
          &__address {
            a {
              font-family: $font-regular;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.55px;
              color: $color-dark-gray;
              &:hover {
                text-decoration: none;
              }
            }
          }
          &__distance-col {
            text-align: right;
          }
        }
        .store-card {
          left: 10px;
          margin-top: 14px;
          &__image,
          &__open .checkbox {
            display: none;
          }

          &__name {
            p {
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -0.65px;
            }
            span {
              font-size: 14px;
              line-height: 22px;
              letter-spacing: -0.65px;
            }
          }
          &__details {
            display: block;
            padding: 15px 35px 0 32px;
          }
          &__open {
            .distance {
              display: none;
            }
          }
          &__time {
            flex-direction: unset;
            padding: 15px 0px 49px;
          }
          &__address {
            address,
            .store-card-phone {
              display: none;
            }
            .book-appointment {
              .kf-react-button.small {
                min-width: 81%;
                justify-content: center;
                padding: 8px 57px;
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      .find-store-landing {
        &__filter {
          width: 100%;
          position: fixed;
          z-index: 999;
          top: 0;
          left: 0;
          background: $color-white;
          padding: 0px 19px;
          display: none;
        }
        &__message {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.65px;
          padding: 0px 18px;
        }
        &__store-message {
          margin-top: 31px;
        }
        &__breadcrumb {
          margin-top: 20px;
          margin-bottom: 20px;
          margin-left: 3px;
        }
        &__search {
          margin: 0px 18px;
        }
        .find-store-landing__search-container {
          padding: 0px;
          margin-bottom: 0px;
        }
        &__location-note {
          padding: 10px 18px;
          justify-content: center;
          span {
            display: flex;
            margin-right: 15px;
          }
        }
        &__list {
          border-bottom: none;
          margin-bottom: 50px;
          height: auto;
        }
        &__tabs {
          margin-right: 0;
        }
        .find-store-landing__result-container {
          margin-left: 0px;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 0px;
          .find-store-browseall-div {
            margin: 0px 18px;
            margin-bottom: 50px;
            .load-more {
              margin-bottom: 20px;
            }
            .load-more--disabled {
              margin-bottom: 20px;
            }
            .kf-react-button.large {
              min-width: 339px;
              &:hover {
                background-color: $color-accent-blue-a;
              }
            }
          }
        }
        &__filter--modal {
          display: block;
        }
        &__filter-panel {
          position: sticky;
        }
        &__show-list {
          padding: 0px 18px;
          height: auto;
          overflow-y: visible;
        }
        .find-store-landing__map {
          display: none;
          margin-top: 0px;
          padding: 0px;
        }
        .find-store-landing__show-map {
          display: block;
          height: 100%;
          padding: 0px;
          margin: 0;
          .react-bingmaps {
            height: 70vh;
          }
        }
        &__view-list {
          text-decoration: underline;
          font-family: $font-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          margin-bottom: 31px;
          cursor: pointer;
          float: right;
          margin-top: 15px;
          padding-right: 18px;
        }
        &__use-location {
          margin-bottom: 31px;
          padding-left: 18px;
        }
        &__tabs {
          overflow-x: auto;
          scroll-behavior: smooth;
          justify-content: flex-start;
          align-items: center;
          padding: 0px 18px;
        }
        &__search-full-container {
          padding-top: 21px;
        }
        &__filter-panel {
          .filter__mobile-header {
            height: auto;
            padding-top: 9px;
          }
          .filter__chips-tag {
            border: solid 1px $color-medium-gray;
          }
          .filter__apply {
            margin-bottom: 50px;
          }
        }
        .store-list {
          padding: 30px 20px;
          &:last-child {
            border-bottom: 1px solid $color-light-gray;
          }
          &__time {
            display: none;
          }
          &__set-store-time {
            display: block;
          }
          &__address {
            a {
              font-family: $font-regular;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.55px;
              color: $color-dark-gray;
              &:hover {
                text-decoration: none;
              }
            }
          }
          &__distance-col {
            text-align: right;
          }

          &__name-col {
            margin-right: 26px !important;
          }

          &__address-col {
            margin-right: 0;
          }
        }

        .store-card {
          display: block;
          width: 100%;
          left: 0;
          bottom: 0;
          &__open .checkbox {
            display: none;
          }
          &__address {
            address,
            .store-card-phone {
              display: none;
            }
            .book-appointment {
              margin-top: 0;
              position: absolute;
              bottom: 16px;
              width: 100%;
              .kf-react-button.small {
                min-width: 86%;
                justify-content: center;
              }
            }
          }
          &__details {
            display: block;
          }
          &__open {
            .distance {
              display: none;
            }
          }
          &__image {
            display: none;
          }
          &__time {
            flex-direction: unset;
            padding: 9px 0px 45px;
          }
          &__name {
            p {
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -0.65px;
            }
          }
        }
      }
      .filter {
        &__mobile-header {
          margin: 9px 0 35px !important;
        }
      }
    }

    @media only screen and (max-width: 480px) {
      .find-store-landing {
        &__tab {
          min-width: -webkit-fit-content;
          min-width: -moz-fit-content;
          min-width: fit-content;
          padding: 0px 20px 15px;
        }
        &__result-container {
          .error-msg {
            padding: 0px 22px;
            margin-bottom: 25px;
          }
          .find-store-browseall-div {
            flex-direction: column;
            margin: 0px 18px;
            margin-bottom: 5px;
          }
          .kf-react-button.large {
            width: 90%;
          }
        }
      }
      .store-list {
        &__name-col {
          display: flex;
          order: 1;
        }
        &__address-col {
          order: 3;
          margin-top: 23px;
        }
        &__distance-col {
          order: 2;
        }
      }
    }
  }
}
